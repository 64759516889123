import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom'; 
import Header from './components/Header';  
import Footer from './components/Footer';  
import About from './pages/About';         
import Strategy from './pages/Strategy';   
import PrivacyPolicy from './pages/PrivacyPolicy'; 
import TermsAndConditions from './pages/TermsAndConditions'; 
import DetailsView from './pages/DetailsView';  
import Home from './pages/Home';  
import { appStyle } from './styles/styles';


function App() {
    const [symbol, setSymbol] = useState('');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [boxSize, setBoxSize] = useState(0);
    const [tooltipVisible, setTooltipVisible] = useState({});

    useEffect(() => {
        document.body.style.margin = '0';
        document.body.style.padding = '0';
    }, []);

    return (
        <>
            <Header />
            <Routes>
                <Route 
                    path="/" 
                    element={<Home 
                        symbol={symbol} 
                        setSymbol={setSymbol}
                    />} 
                />
                <Route path="/about" element={<About />} />
                <Route path="/strategy" element={<Strategy />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route 
                    path="/details" 
                    element={<DetailsView
                        symbol={symbol}
                        setSymbol={setSymbol}
                        data={data}
                        setData={setData}
                        error={error}
                        setError={setError}
                        boxSize={boxSize}
                        setBoxSize={setBoxSize}
                        tooltipVisible={tooltipVisible}
                        setTooltipVisible={setTooltipVisible}
                    />}
                />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
