import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getProfitScoreColor, getProfitScoreDescription, getOrdinalSuffix } from '../utils/colorUtils';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import EventManager from '../utils/EventManager';

// Example styles for the watchlist
const watchlistContainerStyle = {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    height: '100%',
    overflowY: 'auto',  // Enable scroll if the list is long
};

const itemStyle = {
    padding: '20px 0',
    borderBottom: '1px solid #ddd',
    width: '100%',
    display: 'flex',  // Flexbox to align symbol and profit score in a row
    justifyContent: 'space-between',  // Space between text and profit score
    alignItems: 'center',  // Align items vertically in the center
    position: 'relative',  // To allow absolute positioning for the rectangle
};

const symbolContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
};

const symbolStyle = {
    fontSize: '24px',
};

const dateStyle = {
    fontSize: '18px',
    marginTop: '-10px'
};

const exchangeStyle = {
    fontSize: '14px',
    color: '#666',
    fontWeight: 'bold',
};

// Profit score container style updated to be a round rectangle
const profitScoreContainerStyle = {
    position: 'absolute',  // Absolute position to move it 30px from the right
    right: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',  // Set width of the rounded rectangle
    height: '40px',
    borderRadius: '12px',  // Rounded corners
    backgroundColor: 'transparent',
    textAlign: 'center',
};

const profitScoreStyle = {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '18px',
};

const descriptionStyle = {
    marginTop: '4px',
    fontSize: '12px',
    color: '#666',
    width: '80px',  // Match the width of the rectangle
    textAlign: 'center',  // Center the text inside the rectangle
};

// Loader style
const loaderStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    borderTop: '4px solid #3498db',
    marginRight: '52px',
    marginTop: '16px',
    width: '25px',
    height: '25px',
    animation: 'spin 2s linear infinite',
};

// Adding keyframes for the spin animation
const keyframesStyle = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Get current day and month with ordinal suffix
const getCurrentDayAndMonth = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    return `${day}${getOrdinalSuffix(day)} ${month}`;
};

const fetchProfitScore = (symbol) => {
    const payload = { symbol };
    return axios.post(`${BASE_URL}/data`, payload)
        .then(response => {
            if (!response.data || Object.keys(response.data).length === 0) {
                return null;
            }
            return response.data.profit_score;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            return null;
        });
};

// Watchlist component
const Watchlist = ({ symbols }) => {
    const navigate = useNavigate(); // Initialize the navigate function
    const [loadingStates, setLoadingStates] = useState({});
    const [profitScores, setProfitScores] = useState({});

    const currentDayAndMonth = getCurrentDayAndMonth(); // Get current day and month with ordinal suffix

    useEffect(() => {
        const fetchAllScores = () => {
            symbols.forEach(symbol => {
                // Set loading state to true before fetching
                setLoadingStates(prev => ({ ...prev, [symbol.name]: true }));
                
                // Fetch profit score for each symbol
                fetchProfitScore(symbol.exchange + ':' + symbol.name)
                    .then(score => {
                        setProfitScores(prev => ({ ...prev, [symbol.name]: score }));
                        setLoadingStates(prev => ({ ...prev, [symbol.name]: false }));
                    });
            });
        };

        fetchAllScores();  // Fetch all scores when drawer is opened
    }, [symbols]);

    const handleClick = (exchange, symbol) => {
        EventManager.trigger('toggle_watchlist', null);
        navigate(`/details?symbol=${encodeURIComponent(`${exchange}:${symbol}`)}`);
    };

    return (
        <div style={watchlistContainerStyle}>
            {/* Inject keyframes for spin animation */}
            <style>{keyframesStyle}</style>
            <h2>Watchlist</h2>
            <p style={dateStyle}>{currentDayAndMonth}</p> {/* Display current day with ordinal suffix and month */}
            {symbols.length === 0 ? (
                <p>No symbols in watchlist</p>
            ) : (
                symbols.map((symbol, index) => {
                    const isLoading = loadingStates[symbol.name];
                    const profitScore = profitScores[symbol.name];

                    return (
                        <div key={index} style={itemStyle} onClick={() => handleClick(symbol.exchange, symbol.name)}>
                            {/* Symbol and exchange on the left */}
                            <div style={symbolContainerStyle}>
                                <div style={symbolStyle}>{symbol.name}</div>
                                <div style={exchangeStyle}>{symbol.exchange}</div>
                            </div>


                            {/* Profit score or loader on the right inside the rectangle */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-25px' }}>
                                {isLoading ? (
                                    <div style={loaderStyle}></div>  // Show loader while loading
                                ) : (
                                    <div
                                        style={{
                                            ...profitScoreContainerStyle,
                                            backgroundColor: getProfitScoreColor(profitScore),
                                        }}
                                    >
                                        <span style={profitScoreStyle}>{profitScore}</span>
                                    </div>
                                )}

                                {/* Description centered below the rectangle */}
                                {!isLoading && (
                                    <div style={{ width: '80px', position: 'relative', left: '-27px', top: '40px', display: 'flex', justifyContent: 'center' }}>
                                        <span style={descriptionStyle}>
                                            {getProfitScoreDescription(profitScore)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default Watchlist;
